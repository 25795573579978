import React, { useState } from 'react';
import Container from '../components/Container';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import SEO from '../components/SEO';

const CF7Wrapper = ({ children, domain, formid, setSubmitDisabled }) => {
    const [response, setResponse] = useState({
        message: '',
        status: '',
    });

    return (
        <>
            <form
                action={`https://${domain}/wp-json/contact-form-7/v1/contact-forms/${formid}/feedback`}
                method='POST'
                onSubmit={(event) => {
                    event.preventDefault();
                    setSubmitDisabled(true);
                    //@ts-ignore
                    fetch(event.target.action, {
                        method: 'POST',
                        //@ts-ignore
                        body: new FormData(event.target),
                    })
                        .then((resp) => resp.json())
                        .then((data) => {
                            setResponse(data);
                            if (data.status == 'mail_sent') {
                                //@ts-ignore
                                event.target.reset();
                            }
                            setSubmitDisabled(false);
                        })
                        .catch(function (error) {
                            setResponse(error);
                            setSubmitDisabled(false);
                        });
                }}
            >
                {children}
            </form>

            {response.message && (
                <p
                    className={`${
                        response.status === 'mail_sent'
                            ? 'text-green-700 bg-green-100 border-green-700'
                            : 'text-red-700 bg-red-100 border-red-700'
                    } shadow-md font-medium border px-5 py-2 rounded-lg`}
                >
                    {response.message}
                </p>
            )}
        </>
    );
};

export default function Default({ pageContext: { data } }) {
    const clone = (obj) => Object.assign({}, obj);

    const [submitDisabled, setSubmitDisabled] = React.useState(false);

    const renameKey = (object, key, newKey) => {
        const clonedObj = clone(object);
        const targetKey = clonedObj[key];
        delete clonedObj[key];
        clonedObj[newKey] = targetKey;
        return clonedObj;
    };

    const options = {
        lowerCaseAttributeNames: true,
        replace: (node) => {
            const { attribs, children } = node;

            if (attribs && attribs.class === 'wpcf7') {
                return <>{domToReact(children, options)}</>;
            }

            if (attribs && attribs.class === 'screen-reader-response') {
                return <></>;
            }

            if (attribs && attribs.type === 'submit') {
                const props = attributesToProps(node.attribs);
                return (
                    <button {...props} disabled={submitDisabled}>
                        {props.value}{' '}
                        {submitDisabled && (
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='animate-spin inline-block text-white mb-1 stroke-current'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                strokeWidth='1.5'
                                fill='none'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path
                                    stroke='none'
                                    d='M0 0h24v24H0z'
                                    fill='none'
                                />
                                <line x1='12' y1='6' x2='12' y2='3' />
                                <line x1='6' y1='12' x2='3' y2='12' />
                                <line x1='7.75' y1='7.75' x2='5.6' y2='5.6' />
                            </svg>
                        )}
                    </button>
                );
            }

            if (attribs && node.name === 'input') {
                const props = attributesToProps(node.attribs);
                const newProps = renameKey(props, 'value', 'defaultValue');
                return <input {...newProps} />;
            }

            if (attribs && attribs.class === 'wpcf7-form') {
                const formID = attribs.action.substring(
                    attribs.action.indexOf('-') + 2,
                    attribs.action.lastIndexOf('-')
                );

                return (
                    <CF7Wrapper
                        domain='rogamus.de'
                        formid={formID}
                        setSubmitDisabled={setSubmitDisabled}
                    >
                        {domToReact(children, options)}
                    </CF7Wrapper>
                );
            }
        },
    };

    return (
        <Container>
            <SEO
                title={data.title}
                // description={data.description || post.excerpt}
            />
            <div className='d-flex flex-column'>
                <h1>{data.title}</h1>
                {parse(data.content, options)}
            </div>
        </Container>
    );
}
